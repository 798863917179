<template>
    <v-card width="100%">
        <v-card-text>
            <v-row justify="center" class="pa-5">
                <v-expansion-panels
                        v-model="selected"
                        popout focusable>
                    <v-expansion-panel
                            v-for="(round,i) in 3"
                            :key="i"
                    >
                        <v-expansion-panel-header ripple>Round {{round}}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-layout class="round-wrap" wrap>
                                <v-btn class="btn-add primary"
                                       @click="addItem(round)"
                                >
                                    <v-icon>mdi-plus</v-icon>
                                    문항 추가
                                </v-btn>
                                <div class="survey-wrap"
                                     v-for="item in list"
                                     v-if="item.episode === round"
                                >
                                    <h5>문항 {{ item.no }}</h5>
                                    <div class="survey"
                                    >
                                        <img class="background"
                                             src="https://skplay.s3.ap-northeast-2.amazonaws.com/butterfly/images/bg_survey.png" />
                                        <img class="title"
                                             :src="item.title" />

                                        <v-layout justify-space-between class="options">
                                            <div class="option"
                                                 v-for="no in 4"
                                                 v-if="item[`option${no}`]"
                                            >
                                                <img :src="item[`option${no}`]" />
                                                <img src="https://skplay.s3.ap-northeast-2.amazonaws.com/butterfly/images/bg_survey_option.png" class="border" />
                                            </div>

                                        </v-layout>
                                    </div>

                                    <div class="buttons">
                                        <v-btn color="white"
                                               icon
                                               @click="editItem(item)">
                                            <v-icon>mdi-square-edit-outline</v-icon>
                                        </v-btn>
                                        <v-btn color="white"
                                               icon
                                               @click="deleteItem(item)">
                                            <v-icon>mdi-trash-can-outline</v-icon>
                                        </v-btn>
                                    </div>

                                </div>
                            </v-layout>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-row>
        </v-card-text>
        <v-dialog width="1024" v-model="showDialog">
            <v-card>
                <v-card-title>문항{{ item.id ? `${item.no} 수정` : '추가'}}</v-card-title>
                <v-card-text class="form">
                    <div class="survey"
                    >
                        <img class="background"
                             src="https://skplay.s3.ap-northeast-2.amazonaws.com/butterfly/images/bg_survey.png" />
                        <div class="title"
                             @click="fileSelect('title')"
                        >
                            <img :src="item.title" />
                            <div class="mask"></div>
                        </div>

                        <v-layout justify-space-between class="options">
                            <div class="option"
                                 v-for="no in 4"
                                 @click="fileSelect('option', no)"
                            >
                                <img :src="item[`option${no}`]" />
                                <img src="https://skplay.s3.ap-northeast-2.amazonaws.com/butterfly/images/bg_survey_option.png" class="border" />
                                <div class="mask"></div>
                            </div>

                        </v-layout>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            @click="showDialog = false"
                    >
                        취소
                    </v-btn>
                    <v-btn color="primary"
                           @click="save"
                    >
                        저장
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog width="400" v-model="showFileForm">
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                    <v-file-input v-model="file" label="이미지 파일"
                                  accept="image/*"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            @click="showFileForm = false"
                    >
                        취소
                    </v-btn>
                    <v-btn color="primary"
                           @click="upload"
                    >
                        업로드
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
    export default {

        data() {
            return {
                selected: 0,
                list: [],

                showDialog: false,
                showFileForm: false,
                item: {},
                file: null,
                uploadTarget: null,

            }
        },

        mounted() {
            this.getList()
        },

        methods: {
            getList() {
                this.axios.get(`${process.env.VUE_APP_BUTTERFLY_SERVER_HOST}/api/admin/survey`)
                    .then(response => {
                        if (response.data.code === '0000') {
                            this.list = response.data.result
                        }
                    })
            },
            addItem(round) {
                this.item = {
                    episode: round
                }

                this.showDialog = true
            },
            editItem(item) {
                this.item = item

                this.showDialog = true
            },
            deleteItem(item) {
                this.item = item

                this.$store.commit('showDialog', {
                    type: 'confirm',
                    title: `[ROUND${item.episode}의 문항${item.no}] 삭제`,
                    message: '문항을 삭제하시겠습니까?',
                    okCb: _ => {
                        this.axios.delete(`${process.env.VUE_APP_BUTTERFLY_SERVER_HOST}/api/admin/survey/${item.id}`)
                            .then(response => {
                                this.getList()
                            })
                    }
                })
            },

            fileSelect(target, no) {
                this.file = null
                this.showFileForm = true

                this.uploadTarget = {
                    target: target,
                    no: no
                }
            },

            upload() {

                if (!this.file) {
                    this.$store.commit('showDialog', {
                        type: 'alert',
                        message: '선택된 파일이 없습니다.'
                    })
                    return
                }

                const formData = new FormData()
                formData.append('file', this.file)

                const options = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }

                this.axios.post(`${process.env.VUE_APP_BUTTERFLY_SERVER_HOST}/api/admin/survey/image`, formData, options).then(response => {
                    this.showFileForm = false

                    const result = response.data.result

                    if (this.uploadTarget.target === 'title') {
                        this.item.title = result.url
                    } else if (this.uploadTarget.target === 'option') {
                        this.item[`option${this.uploadTarget.no}`] = result.url
                    }
                })
            },

            save() {

                let valid = true

                if (!this.item.title) valid = false

                _.range(1,5).forEach(n => {
                    if (!this.item[`option${n}`]) valid = false
                })
                if (!valid) {
                    this.$store.commit('showDialog', {
                        type: 'alert',
                        message: '모든 이미지 항목을 업로드하세요.',
                    })
                    return
                }

                this.axios.post(`${process.env.VUE_APP_BUTTERFLY_SERVER_HOST}/api/admin/survey`, this.item).then(response => {
                    this.showDialog = false

                    this.getList()
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .round-wrap {
        position: relative;
        margin-left: -10px;
        margin-right: -10px;
        padding-top: 40px;

        .btn-add {
            position: absolute;
            right: 10px;
            top: 10px;
        }

        .survey-wrap {
            width: 640px;
            margin-top: 10px;
            margin-left: 10px;
            margin-right: 10px;
            position: relative;

            img {
                max-width: 100%;
            }

            h5 {
                position: absolute;
                width: 100%;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: Roboto, sans-serif;
                font-weight: 600;
                color: #ffffff;
                background-image: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2));
                z-index: 2;
            }

            .buttons {
                position: absolute;
                right: 5px;
                top: 5px;
                z-index: 2;
            }

            .survey {
                width: 640px;
                height: 400px;


                .background {
                    width: 640px;
                }

                .title {
                    position: absolute;
                    left: 25px;
                    top: 25px;
                    width: 590px;
                }

                .options {
                    position: absolute;
                    left: 25px;
                    right: 25px;
                    bottom: 70px;
                    height: 136px;

                    .option {
                        width: 140px;
                        height: 100%;
                        position: relative;

                        .border {
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            border: unset !important;
                        }
                    }
                }
            }
        }
    }

    .form {
        display: flex;
        justify-content: center;
        position: relative;

        img {
            max-width: 100%;
        }

        .survey {
            width: 960px;
            height: 600px;
            position: relative;


            .background {
                width: 960px;
            }

            .title {
                position: absolute;
                left: 37px;
                top: 37px;
                width: 885px;
                height: 228px;
            }

            .options {
                position: absolute;
                left: 37px;
                right: 37px;
                bottom: 105px;
                height: 204px;

                .option {
                    width: 210px;
                    height: 100%;
                    position: relative;

                    .border {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        border: unset !important;
                    }
                }
            }
        }

        .mask {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0; top: 0;
            cursor: pointer;

            background-color: rgba(255,255,255,0.1);

            &:hover {
                background-color: rgba(255,255,255,0.3);
            }
        }
    }

</style>