<template>
    <v-card width="100%">
        <v-card-title>결과 PDF 관리</v-card-title>
<!--        <v-card-actions>-->
<!--            <v-select :items="trainings"-->
<!--                      item-value="id" item-text="name"-->
<!--                      v-model="currentTraining"-->
<!--                      outlined-->
<!--                      return-object-->
<!--                      hide-details-->
<!--                      label="교육과정"-->
<!--                      @change="$router.push(`/manage/result/image/${currentTraining.id}`)"-->
<!--            />-->
<!--            <v-spacer></v-spacer>-->
<!--        </v-card-actions>-->
        <v-card-text>
            <v-layout wrap>
                <div class="thumb"
                     v-for="(result, i) in results"
                     @click="selectResult(result)"
                >
                    <img :src="`https://skplay.s3.ap-northeast-2.amazonaws.com/results/icon_result_${result.result_num}.png`" />
                    <div class="name">{{ result.type }}</div>
                </div>
            </v-layout>
            <v-divider></v-divider>
            <v-layout class="preview" v-if="currentResult" align-start >
                <v-card class="pdf-wrap">
                    <v-card-title>PDF</v-card-title>
	                <v-card-text>
                        <iframe :src="currentResult.pdf" class="pdf" frameborder="0" width="100%" />
                    </v-card-text>
                </v-card>
                <v-card class="form">
                    <v-card-title>
                        PDF 파일 변경
                    </v-card-title>
                    <v-divider class="ma-0"></v-divider>
                    <v-card-text>
                        <v-file-input
                                v-model="pdfFile"
                                class=""
                                label="PDF 파일"
                                truncate-length="40"
                                show-size
                                accept="application/pdf"
                                @change="uploadPdf"
                        ></v-file-input>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="kb-primary" :disabled="!changed" @click="save()">저장</v-btn>
                    </v-card-actions>
                </v-card>
            </v-layout>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        components: {
        },
        data() {
            return {
                trainings: [],
                currentTraining: {},
                results: [],
                apiUrl: process.env.VUE_APP_WEEKLY_SERVER_HOST,
                pdfFile: null,
                currentResult: null,
                type: '',
                changed: false,
                currentPage: 1,
                pageCount: 0,
            }
        },
        watch: {
            $route(to, from) {
                const id = to.params.id
                this.trainings.forEach(training => {
                    if (training.id === id) {
                        this.currentTraining = training
                    }
                })
                this.getList()
            },

        },
        filters: {
            lpad(val) {
                if (!val) return ''

                if (val.toString().length < 2) {
                    val = '0' + val
                }

                return val
            }
        },
        mounted() {
            this.currentTraining.id = this.$route.params.id || this.$cookie.get('training_id')
            this.getTrainings()
        },
        methods: {
            getTrainings() {
                this.axios.get(`${this.apiUrl}/api/admin/training`).then(response => {
                    if (response.data.code === '0000') {
                        this.trainings = response.data.result

                        if (this.trainings.length > 0) {
                        	if (this.currentTraining.id) {
                                this.trainings.forEach(training => {
                                    if (training.id == this.currentTraining.id) {
                                        this.currentTraining = training
                                    }
                                })
                            } else {
                                this.currentTraining = this.trainings[0]
                            }
                        }
                        this.getList()
                    }
                })
            },
            getList() {
                const self = this
                const params = {
                    'training_id': this.currentTraining.id,
                }
                this.axios.get(`${this.apiUrl}/api/admin/result/items`, {params: params}).then(response => {
                    if (response.data.code === '0000') {
                        self.results = []
                        Object.keys(response.data.result).forEach((key, index) => {
                            self.results.push(response.data.result[key])
                        })
                    }
                })
            },
            selectResult(result) {
                this.currentResult = result
                this.pdfFile = null
                this.changed = false
            },
            uploadPdf() {

                const self = this

                if (!this.pdfFile) return

                const formData = new FormData()
                formData.append('file', this.pdfFile)

                const options = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }

                this.axios.post(`${process.env.VUE_APP_WEEKLY_SERVER_HOST}/api/admin/item/pdf`, formData, options).then(response => {
                    self.currentResult.pdf = response.data.result.url
                    self.changed = true
                })
            },

            save() {

                this.currentResult.training_id = this.currentTraining.id

                this.axios.post(`${this.apiUrl}/api/admin/results`, this.currentResult).then(response => {
                    if (response.data.code === '0000') {

                        const index = this.results.indexOf(this.currentResult)
                        this.results[index] = response.data.result
                        this.currentResult = this.results[index]
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    img { max-width: 100%; }

    .thumb {
        /*background: #efefef;*/
        border-radius: 10px;
        margin: 3px;
        width: 150px;
        cursor: pointer;
        position: relative;

        .name {
            font-family: GmarketSans, sans-serif;
            font-weight: 600;
            font-size: 16px;
            text-align: center;
            position: absolute;
            bottom: 3px;
            left: 3px;
            right: 4px;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            padding-top: 20px;
            padding-bottom: 10px;
            background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.7) 50%, rgba(0,0,0,0) 100%);
            color: #fff;
        }
    }

    .preview {
        img {
            border: 1px solid #ccc;
        }
        .pdf-wrap {
            width: 60%;
        }
        .pdf {
            width: 100%;
            height: calc(50vw * 1.414 / 1);
        }
        .dn-image {
            margin-left: 20px;
            width: 30%;
        }

        .form {
            margin-left: 20px;
            width: 30%;
        }
    }
</style>
