<template>
    <v-card width="100%" class="form">
        <v-card-title>{{ trainee.id ? '교육생 정보 수정' : '교육생 개별 추가' }}</v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid">
                <v-layout justify-center column>
                    <v-row no-gutters>
                        <v-col cols="3">
                            <v-subheader>이름</v-subheader>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                    v-model="trainee.name"
                                    outlined
                                    dense
                                    required
                                    :rules="[v => !!v || '필수항목입니다.']"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="3">
                            <v-subheader>직원번호</v-subheader>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                    v-model="trainee.id_number"
                                    outlined
                                    dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="3">
                            <v-subheader>직급</v-subheader>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                    v-model="trainee.position"
                                    outlined
                                    dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="3">
                            <v-subheader>소속</v-subheader>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                    v-model="trainee.division"
                                    outlined
                                    dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="3">
                            <v-subheader>성별</v-subheader>
                        </v-col>
                        <v-col cols="6">
                            <v-btn-toggle
                                v-model="trainee.gender"
                                color="brown darken-2"
                                tile
                                group
                            >
                                <v-btn value="M">
                                남성
                                </v-btn>
                                <v-btn value="F">
                                여성
                                </v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="3">
                            <v-subheader>E-mail</v-subheader>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                    type="email"
                                    v-model="trainee.email"
                                    outlined
                                    dense
                                    required
                                    :rules="[v => !!v || '필수항목입니다.']"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="3">
                            <v-subheader>PW</v-subheader>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="trainee.pwd"
                                outlined
                                dense
                                hint="* 입력하지 않으면 자동생성됩니다."
                                persistent-hint
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-layout>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <button class="btn btn-primary mr-2" @click="$router.back()">뒤로</button>
            <button class="btn btn-secondary" @click="save()">완료</button>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default  {
	    data() {
		    return {
                trainee: {
                },
                valid: false,
		    }
	    },
    	mounted() {
            const self = this

	    	const id = this.$route.params.id;

	    	if (id) {
			    this.axios.get(`${process.env.VUE_APP_BUTTERFLY_SERVER_HOST}/api/admin/trainee/${id}`).then(response => {
				    if (response.data.code === '0000') {
                        self.trainee = response.data.result

                        $('.form').show();
				    }
			    })
		    } else {
			    $('.form').show();
		    }
	    },
	    methods: {
		    save() {

                const self = this

		    	if (!this.$refs.form.validate()) return;

                if (!this.trainee.pwd) {
                    this.trainee.pwd = this.generatePassword()
                }

			    this.axios.post(`${process.env.VUE_APP_BUTTERFLY_SERVER_HOST}/api/admin/trainee`, this.trainee).then(response => {
				    if (response.data.code === '0000') {
				    	self.$router.replace(`/butterfly/trainees`)
				    } else {
                        self.$store.commit('showDialog', {
                            type: 'alert',
                            message: response.data.message
                        })
                    }
			    })
		    },
            generatePassword() {
                const num = '23456789';
                const str = 'abcdefghjkmnpqrstuvwxyz';
                let result = '';
                for ( let i = 0; i < 5; i++ ) {
                    result += num.charAt(Math.floor(Math.random() * num.length));
                }
                for ( let i = 0; i < 2; i++ ) {
                    result += str.charAt(Math.floor(Math.random() * str.length));
                }
                return result;
            },
	    },
    }
</script>

<style lang="scss" scoped>
    .form { display: none}
</style>
