<template>
    <v-card width="100%">
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="이름, 직원번호, 직급, 소속, 이메일"
                single-line
                hide-details
                clearable
            ></v-text-field>
        </v-card-actions>
        <v-card-actions class="fixed-buttons pl-0 pr-0">
            <v-btn @click="openMailDialog()" class="ml-5">
                ID/PW 발송
            </v-btn>
            <v-btn @click="cancelMail()" class="ml-2">
                발송예약 취소
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="$router.push(`/butterfly/trainee/form`)">
                <v-icon left>mdi-plus</v-icon>교육생 개별 추가
            </v-btn>
            <v-btn @click="deleteAll" class="mr-5">
                모두 삭제
            </v-btn>
        </v-card-actions>
        <v-card-text>
            <v-data-table
                v-model="selected"
                item-key="id"
                show-select
                fixed-header
                calculate-widths
                :headers="headers"
                :items="trainees"
                :items-per-page="-1"
                :loading="isLoading"
                :search="search"
                loading-text="데이터 조회 중.. 잠시만 기다리세요."
                hide-default-footer
            >
                <template v-slot:item.gender="{ item, index }">
                    {{ item.gender === 'M' ? '남성' : (item.gender === 'F' ? '여성' : '') }}
                </template>
                <template v-slot:item.sent="{ item, index }">
                    {{ item.sent ? `발송완료(${item.sent})` : ''}}
                </template>
                <template v-slot:item.actions="{ item, index }">
                    <div class="text-center" style="width:170px;">
                        <v-btn small color="secondary"
                               @click="editItem(item, index)"
                               class="mr-1"
                        >수정</v-btn>
                        <v-btn small color="#C72D1B" style="color: #fff !important;"
                                @click="deleteItem(item, index)"
                               class="mr-1"
                        >삭제</v-btn>
                        <v-btn small color="#2BAE66" style="color: #fff !important;"
                               @click="resetItem(item, index)"
                        >초기화</v-btn>
                    </div>
                </template>
            </v-data-table>
            <v-divider></v-divider>
<!--            <div class="text-center pt-2 mt-5">-->
<!--                <v-pagination-->
<!--                    v-model="page"-->
<!--                    :length="pageCount"-->
<!--                    ></v-pagination>-->
<!--            </div>-->
        </v-card-text>
        <v-card-actions>
<!--            <div class="pl-5" style="width:100px;">-->
<!--                <v-select label="목록개수"-->
<!--                    v-model="itemsPerPage"-->
<!--                    :items="[10,15,20,30,50,100,200,1000]"-->
<!--                />-->
<!--            </div>-->
            <v-spacer></v-spacer>
        </v-card-actions>

        <v-dialog v-model="showMailDialog" width="900" style="overflow-x:hidden">
            <v-card>
                <v-card-title>
	                ID/PW 발송
                </v-card-title>
                <v-card-text>
                    <v-layout justify-center column>
                        <v-row no-gutters>
                            <v-col cols="3">
                                <v-subheader>템플릿 선택</v-subheader>
                            </v-col>
                            <v-col cols="9" align-self="center">
                                <v-select :items="templates" item-text="name"
                                          v-model="template"
                                          label="메일템플릿"
                                          outlined dense hide-details
                                          return-object
                                          @change="onSelectTemplate" />
                            </v-col>
                            <v-col cols="3" class="mt-5">
                                <v-subheader>제목</v-subheader>
                            </v-col>
                            <v-col cols="9" align-self="center" class="mt-5">
                                <v-text-field v-model="subject" hide-details dense outlined></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-subheader>메일본문</v-subheader>
                                <v-layout column class="tags">
                                    <p style="font-size:12px;">* 아래 치환자를 마우스로 끌어 놓으세요.</p>
                                    <p><span v-text="`{{url}}`"></span> 접속 주소</p>
                                    <p><span v-text="`{{datetime}}`"></span> 교육진행일시</p>
                                    <p><span v-text="`{{name}}`"></span> 교육생명</p>
                                    <p><span v-text="`{{email}}`"></span> 이메일주소</p>
                                    <p><span v-text="`{{pw}}`"></span> 패스워드</p>
                                    <p><span v-text="`{{pdf}}`"></span> 결과PDF링크</p>
                                </v-layout>
                            </v-col>
                            <v-col cols="9" align-self="center">
                                <div class="tiptap-editor">
                                    <editor-menu-bar
                                            :editor="editor"
                                            v-slot="{ commands, isActive }"
                                    >
                                        <div class="menubar">
                                            <v-layout class="toolbar" align-center>
                                                <button class="menubar__button" @click="commands.undo" title="되돌리기"><img width="15" src="/images/icons/undo.svg" /></button>
                                                <button class="menubar__button" @click="commands.redo"><img width="15" src="/images/icons/redo.svg" title="다시실행" /></button>
                                                <button class="menubar__button ml-5" :class="{ 'is-active': isActive.bold() }" @click="commands.bold" title="굵게"><img width="15" src="/images/icons/bold.svg" /></button>
                                                <button class="menubar__button" :class="{ 'is-active': isActive.italic() }" @click="commands.italic" title="기울이기"><img width="15" src="/images/icons/italic.svg" /></button>
                                                <button class="menubar__button" :class="{ 'is-active': isActive.strike() }" @click="commands.strike" title="취소선"><img width="15" src="/images/icons/strike.svg" /></button>
                                                <button class="menubar__button" :class="{ 'is-active': isActive.underline() }" @click="commands.underline" title="밑줄"><img width="15" src="/images/icons/underline.svg" /></button>
                                                <!--                                                <button class="menubar__button" :class="{ 'is-active': isActive.code() }" @click="commands.code"><img width="15" src="/images/icons/code.svg" /></button>-->
                                                <!--                                                <button class="menubar__button" :class="{ 'is-active': isActive.paragraph() }" @click="commands.paragraph"><img width="15" src="/images/icons/paragraph.svg" /></button>-->
                                                <button class="menubar__button ml-5" :class="{ 'is-active': isActive.heading({ level: 1 }) }" @click="commands.heading({ level: 1 })" title="제목1">H1</button>
                                                <button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 2 }) }" @click="commands.heading({ level: 2 })" title="제목2">H2</button>
                                                <button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 3 }) }" @click="commands.heading({ level: 3 })" title="제목3">H3</button>
                                                <button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 4 }) }" @click="commands.heading({ level: 4 })" title="제목4">H4</button>
                                                <button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 5 }) }" @click="commands.heading({ level: 5 })" title="제목5">H5</button>
                                                <button class="menubar__button ml-5" :class="{ 'is-active': isActive.bullet_list() }" @click="commands.bullet_list" title="목록"><img width="15" src="/images/icons/ul.svg" /></button>
                                                <button class="menubar__button" :class="{ 'is-active': isActive.ordered_list() }" @click="commands.ordered_list" title="순서목록"><img width="15" src="/images/icons/ol.svg" /></button>
                                                <button class="menubar__button" :class="{ 'is-active': isActive.blockquote() }" @click="commands.blockquote" title="인용구"><img width="15" src="/images/icons/quote.svg" /></button>
                                                <!--                                                <button class="menubar__button" :class="{ 'is-active': isActive.code_block() }" @click="commands.code_block"><img width="15" src="/images/icons/code.svg" /></button>-->
                                            </v-layout>
                                        </div>
                                    </editor-menu-bar>

                                    <editor-content class="editor__content" :editor="editor" />
                                </div>
                            </v-col>
                        </v-row>
                    </v-layout>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="secondary" @click.native="send()">즉시발송</v-btn>
                    <v-spacer></v-spacer>
	                <v-text-field label="예약일자" type="date"
                                  v-model="scheduledDate"
                                  dense outlined hide-details class="mr-3" />
                    <v-text-field label="예약시각" type="time"
                                  v-model="scheduledTime"
                                  dense outlined hide-details class="mr-3" />
                    <v-btn color="secondary" @click.native="send('scheduled')">예약발송</v-btn>
                    <v-btn @click.native="showMailDialog=false">취소</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
    import Buttons from "../../trainings/Buttons";
    import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
    import {
        Blockquote,
        CodeBlock,
        HardBreak,
        Heading,
        HorizontalRule,
        OrderedList,
        BulletList,
        ListItem,
        TodoItem,
        TodoList,
        Bold,
        Code,
        Italic,
        Link,
        Strike,
        Underline,
        History,
    } from 'tiptap-extensions'
    export default {
    	components: {
    		Buttons,
            EditorMenuBar,
            EditorContent,
        },
	    data() {
		    return {
		    	page: 1,
                pageCount: 0,
			    itemsPerPage: 100,
                training: {},
                trainees: [],
			    headers: [
                    {text: '#', sortable: true, value: 'id'},
				    {text: '이름', sortable: true, value: 'name'},
				    {text: '직원번호', sortable: true, value: 'id_number'},
				    // {text: '직급', sortable: true, value: 'position', width: 72 },
				    // {text: '소속', sortable: true, value: 'division', width: 92 },
				    {text: '성별', sortable: true, value: 'gender'},
				    {text: 'E-mail (ID)', sortable: true, value: 'email'},
				    {text: 'PW', sortable: true, value: 'pwd'},
				    {text: '', sortable: false, value: 'actions'},
			    ],
                isLoading: true,
                selected: [],
                search: '',
                showDialog: false,
                file: null,

                showMailDialog: false,
                item: null,
			    templates: [],
                template: null,
                subject: '',
                body: '',
                scheduledDate: '',
                scheduledTime: '',
                editor: new Editor({
                    extensions: [
                        new Blockquote(),
                        new BulletList(),
                        new CodeBlock(),
                        new HardBreak(),
                        new Heading({ levels: [1, 2, 3] }),
                        new HorizontalRule(),
                        new ListItem(),
                        new OrderedList(),
                        new TodoItem(),
                        new TodoList(),
                        new Link(),
                        new Bold(),
                        new Code(),
                        new Italic(),
                        new Strike(),
                        new Underline(),
                        new History(),
                    ],
                }),
		    }
	    },
        watch: {
            showDialog() {
                this.file = null
            }
        },
        beforeDestroy() {
            this.editor.destroy()
        },
        mounted() {

	    	// this.training.id = this.$route.params.id
            this.training.id = 3

            this.$cookie.set('training_id', this.training.id)

            this.getMailTemplates();
            this.getList()

            $(document.body).off('mousedown', '.tags span').on('mousedown', '.tags span', this.selectText)

            $('.v-data-table-header').clone()
                    .removeClass('v-data-table-header')
                    .addClass('cloned-header')
                    .insertBefore('.v-data-table-header')
            // $('.v-data-table-header').scrollToFixed({
            //     marginTop: 64 + 52,
            //     zIndex: 200,
            // })
            //
            $('.fixed-buttons').scrollToFixed({
                marginTop: 64,
                zIndex: 200,
            })
        },
        methods: {
            openMailDialog() {
                if (!this.selected.length) {
                	this.$store.commit('showDialog', {
                	    type: 'alert',
                        message: '선택된 교육생이 없습니다.'
                    })
	                return
                }
                this.onSelectTemplate(this.templates[0])

                this.template = this.templates[0]
                this.item = this.template
                this.scheduledDate = ''
                this.scheduledTime = ''
    	    	this.showMailDialog = true
            },
            send(type) {
                this.showDialog = false

                let message = `선택한 ${this.selected.length}명의 교육생에게 메일을 `
                if (type === 'scheduled') {
                	if (!this.scheduledDate || !this.scheduledTime) {
                        this.$store.commit('showDialog', {
                            type: 'alert',
                            message: '예약일시가 설정되지 않았습니다.'
                        })
                	    return
                    }
                    message += `[${this.scheduledDate} ${this.scheduledTime}]에 예약 발송합니다.`
                } else {
                	message += '즉시 발송합니다.'
                }

                let body = this.editor.getHTML()
                body = body.replace(/<blockquote>/g, "<blockquote style=\"border-left: 3px solid rgba(0, 0, 0, 0.1);color: rgba(0, 0, 0, 0.8);padding-left: 0.8rem;font-style: italic;\" class='gmail_quote'>")

                this.$store.commit('showDialog', {
                	type: 'confirm',
                    message: message,
                    okCb: _ => {
                        const params = {
                        	id: this.item ? this.item.id : null,
                            training_id: this.training.id,
                            trainees: this.selected.map(item => { return item.id }),
	                        type: 'invitation',
                            subject: this.subject,
                            body: body,
	                        is_scheduled: !!type,
                        }
                        if (this.scheduledDate) {
                            params.scheduled = `${this.scheduledDate} ${this.scheduledTime}`
                        }

                        this.isLoading = true
                        this.axios.post(`${process.env.VUE_APP_BUTTERFLY_SERVER_HOST}/api/admin/send`, params).then(response => {
                            if (response.data.code === '0000') {
                                this.isLoading = false
                                this.getMailTemplates()
                                this.getList()

                                this.showMailDialog = false

                                let message
	                            if (!!type) {
	                                message = '메일 발송을 예약했습니다.'
                                } else {
                                    message = '메일 발송 요청을 완료했습니다.'
                                }

                                this.$store.commit('showSnackbar', {
                                    message: message,
                                    color: 'success',
                                    duration: 3000,
                                })
                            }
                        })
                    }
                })

            },
            cancelMail() {
                this.$store.commit('showDialog', {
                    type: 'confirm',
                    message: '선택한 교육생의 발송예약을 취소하시겠습니까?',
                    okCb: _ => {
                        const ids = this.selected.map(item => { return item.id })

                        const params = {
                            ids: ids,
                            type: 'invitation',
                        }

                        this.axios.post(`${process.env.VUE_APP_BUTTERFLY_SERVER_HOST}/api/admin/mail/cancel`, params).then(response => {
                            this.getList()
                        })
                    }
                })
            },
            getMailTemplates() {

    	        const params = {
    	            type: 'invitation'
                }

                this.axios.get(`${process.env.VUE_APP_BUTTERFLY_SERVER_HOST}/api/admin/templates`, {params: params}).then(response => {
                    this.templates = response.data.result
                })
            },
            getList() {
                this.isLoading = true
                this.axios.get(`${process.env.VUE_APP_BUTTERFLY_SERVER_HOST}/api/admin/trainee`).then(response => {
                    if (response.data.code === '0000') {
                        this.isLoading = false

	                    const trainees = []
                        response.data.result.forEach(trainee => {
                        	if (trainee.mails && trainee.mails.length > 0) {
                        		trainee.mails.forEach(mail => {
                                    if (mail.status === 'sent') {
                                        trainee.sent = mail.sent_at
                                    }
                                    if (mail.status === 'scheduled') {
                                        trainee.scheduled = mail.scheduled
                                    }
                                })

                            }
                            trainees.push(trainee)
                        })

                        this.trainees = trainees

                        this.selected = this.trainees
                    }
                })
            },
            editItem(item, index) {
                this.$router.push(`/butterfly/trainee/form/${item.id}`)
            },
            deleteAll() {
                const self = this

                if (!this.selected.length) {
                    this.$store.commit('showDialog', {
                        type: 'alert',
                        message: '선택된 교육생이 없습니다.',
                    })
                    return
                }
                this.$store.commit('showDialog', {
	    			type: 'confirm',
                    message: `선택된 모든 교육생을 삭제하시겠습니까?`,
                    okCb: _ =>{


                        const ids = []
                        this.selected.forEach(item => {
                            ids.push(item.id)
                        })

                        self.axios.delete(`${process.env.VUE_APP_BUTTERFLY_SERVER_HOST}/api/admin/trainee/${JSON.stringify(ids)}`).then(response => {
                            if (response.data.code === '0000') {
                                self.getList()
                            }
                        })
                    }
                })
            },
	    	deleteItem(item, index) {
                const self = this

	    		this.$store.commit('showDialog', {
	    			type: 'confirm',
                    message: `[${item.name}] 교육생을 삭제하시겠습니까?`,
                    okCb: _ =>{

                        const ids = [ item.id ]

                        self.axios.delete(`${process.env.VUE_APP_BUTTERFLY_SERVER_HOST}/api/admin/trainee/${JSON.stringify(ids)}`).then(response => {
                            if (response.data.code === '0000') {
                                self.getList()
                            }
                        })
                    }
                })
            },
            resetItem(item, index) {
                const self = this

                this.$store.commit('showDialog', {
                    type: 'confirm',
                    message: `[${item.name}] 교육생의 플레이 데이터를 초기화하시겠습니까?`,
                    okCb: _ =>{

                        const params = {
	                        id: item.id
                        }

                        self.axios.post(`${process.env.VUE_APP_BUTTERFLY_SERVER_HOST}/api/admin/trainee/reset`, params).then(response => {
                            if (response.data.code === '0000') {
                                self.getList()
                            }
                        })
                    }
                })
            },

            moveTo(path) {
	    		this.$eventBus.$emit('MOVE_TO', path)
            },

            downloadTemplate() {
                location.href=`${process.env.VUE_APP_BUTTERFLY_SERVER_HOST}/download/template`
            },
            upload() {
                const self = this

                if (!this.file) {
                    this.$store.commit('showDialog', {
                        type: 'alert',
                        message: '선택된 파일이 없습니다.'
                    })
                    return
                }

                const formData = new FormData()
                formData.append('file', this.file)

                const options = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }

                this.axios.post(`${process.env.VUE_APP_BUTTERFLY_SERVER_HOST}/api/admin/training`, formData, options).then(response => {
                    self.showDialog = false
                    self.getList()
                })
            },

            selectText(e) {
                const $newSelection = $(e.currentTarget);
                const selection = window.getSelection();
                const range = document.createRange();
                range.setStartBefore($newSelection.first()[0]);
                range.setEndAfter($newSelection.last()[0]);
                selection.removeAllRanges();
                selection.addRange(range);
            },
            onSelectTemplate(item) {
    	        this.item = item
    	    	this.subject = item.subject
	            this.editor.setContent(item.body)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .list_title { height: 64px;}

    .tags {
        background-color: #efefef;
        margin-left: -10px;
        margin-right: 10px;
        padding: 5px;

        span {
            background-color: #fff;
            padding: 3px;
            color: #000;
            cursor: move;
        }
    }

    .fixed-buttons {
        width: 100%;
        background-color: #fff;

        &.scroll-to-fixed-fixed {
            margin-right: -16px !important;
        }
    }

</style>

<style>
    .theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
        position: absolute;
        right: 0;
    }
    .cloned-header {
        visibility: hidden;
        height: 0;
        line-height: 0;
    }

    .cloned-header th {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        height: 0 !important;
    }

</style>
