<template>
    <v-data-table
            :headers="headers"
            :items="users"
            sort-by="calories"
            class="elevation-1"
    >
        <template v-slot:top>
            <v-toolbar
                    flat
            >
                <v-toolbar-title>관리자계정 관리</v-toolbar-title>
                <v-divider
                        class="mx-4"
                        inset
                        vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-dialog
                        v-model="dialog"
                        max-width="600px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                                color="btn-secondary"
                                dark
                                class="mb-2"
                                v-bind="attrs"
                                v-on="on"
                        >
                            관리자 추가
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col
                                            cols="6"
                                    >
                                        <v-text-field
                                                v-model="editedItem.name"
                                                label="이름"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                            cols="6"
                                    >
                                        <v-checkbox label="슈퍼유저" v-model="editedItem.is_superuser" />
                                    </v-col>
                                    <v-col
                                            cols="6"
                                    >
                                        <v-text-field
                                                v-model="editedItem.email"
                                                label="이메일"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                            cols="6"
                                    >
                                        <v-text-field
                                                v-model="editedItem.username"
                                                label="로그인ID"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                            cols="6"
                                    >
                                        <v-text-field
                                                type="password"
                                                v-model="editedItem.passwd"
                                                label="비밀번호"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                            cols="6"
                                    >
                                        <v-text-field
                                                type="password"
                                                v-model="editedItem.passwd_confirm"
                                                label="비밀번호 확인"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" v-if="editedIndex > -1">
                                        <v-subheader>* 비밀번호는 변경시에만 입력하세요.</v-subheader>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                            >
                                취소
                            </v-btn>
                            <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="save"
                            >
                                저장
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                        <v-card-title class="headline">관리자를 삭제하시겠습니까?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeDelete">취소</v-btn>
                            <v-btn color="blue darken-1" text @click="deleteItemConfirm">확인</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.is_superuser="{ item }">
            {{ item.is_superuser ? '예' : '아니오'}}
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
            >
                mdi-pencil
            </v-icon>
            <v-icon
                    small
                    @click="deleteItem(item)"
            >
                mdi-delete
            </v-icon>
        </template>
    </v-data-table>
</template>

<script>
    export default {
        data: () => ({
            dialog: false,
            dialogDelete: false,
            headers: [
                {
                    text: '로그인 ID',
                    align: 'start',
                    value: 'username',
                },
                { text: '이름', value: 'name' },
                { text: '이메일', value: 'email' },
                { text: '슈퍼유저', value: 'is_superuser' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            users: [],
            editedIndex: -1,
            editedItem: {
                id: null,
                username: '',
                name: '',
                email: '',
                is_superuser: 0,
            },
            defaultItem: {
                id: null,
                username: '',
                name: '',
                email: '',
                is_superuser: 0,
            },
        }),

        computed: {
            formTitle () {
                return this.editedIndex === -1 ? '관리자 추가' : '관리자 수정'
            },
        },

        watch: {
            dialog (val) {
                val || this.close()
            },
            dialogDelete (val) {
                val || this.closeDelete()
            },
        },

        mounted() {
            this.getList()
        },

        methods: {
            getList() {

                this.axios.get(`${process.env.VUE_APP_WEEKLY_SERVER_HOST}/api/admin/users`).then(response => {
                    this.users = response.data.result
                })
            },

            editItem (item) {
                this.editedIndex = this.users.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialog = true
            },

            deleteItem (item) {
                this.editedIndex = this.users.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },

            deleteItemConfirm () {
                this.axios.delete(`${process.env.VUE_APP_WEEKLY_SERVER_HOST}/api/admin/users/${this.editedItem.id}`).then(response => {
                    this.getList()
                })
                this.closeDelete()
            },

            close () {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            closeDelete () {
                this.dialogDelete = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            save () {
                if (!this.editedItem.name) {
                    this.$store.commit('showDialog', {
                        type: 'alert',
                        message: '이름을 입력하세요..'
                    })
                    return
                }
                if (!this.editedItem.username) {
                    this.$store.commit('showDialog', {
                        type: 'alert',
                        message: '로그인ID를 입력하세요..'
                    })
                    return
                }

                if (!this.editedItem.id) {
                    if (!this.editedItem.passwd || !this.editedItem.passwd_confirm) {
                        this.$store.commit('showDialog', {
                            type: 'alert',
                            message: '비밀번호를 입력하세요.'
                        })
                        return
                    }
                }
                if (this.editedItem.passwd !== this.editedItem.passwd_confirm) {
                    this.$store.commit('showDialog', {
                        type: 'alert',
                        message: '비밀번호가 일치하지 않습니다.'
                    })
                    return
                }
                this.axios.post(`${process.env.VUE_APP_WEEKLY_SERVER_HOST}/api/admin/users`, this.editedItem).then(response => {
                    this.getList()
                })
                this.close()
            },
        },
    }
</script>