import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
// Vue.use(VueLodash, { name: 'custom' , lodash: lodash })

export default new Router({
    mode: 'history',
    routes: [
        {
        	path: '/',
            redirect: '/login',
        },
        {
            path: '/login',
            name: 'Login',
            component: require('../components/login/Login').default
        },
        {
            path: '/main',
            name: 'Main',
            component: require('../components/main/Main').default,
            children: [
                {
                    path: '/manage/user',
                    name: 'User',
                    components: {
                        main: require('../components/manage/User').default
                    }
                },

                {
                    path: '/weekly',
                    redirect: '/weekly/results/total',
                },
                {
                    path: '/weekly/trainees',
                    name: 'TraineeList',
                    components: {
                        main: require('../components/weekly/trainees/TraineeList').default
                    }
                },
                {
                    path: '/weekly/trainee/form/:id?',
                    name: 'TraineeForm',
                    components: {
                        main: require('../components/weekly/trainees/TraineeForm').default
                    }
                },
                {
                    path: '/weekly/manage/items/:id?',
                    name: 'ItemManage',
                    components: {
                        main: require('../components/weekly/manage/items/Item').default
                    }
                },
                {
                    path: '/weekly/manage/item/:id',
                    name: 'ItemManageForm',
                    components: {
                        main: require('../components/weekly/manage/items/ItemForm').default
                    }
                },
                {
                    path: '/weekly/manage/audio/:id?',
                    name: 'AudioManage',
                    components: {
                        main: require('../components/weekly/manage/Audio').default
                    }
                },
                {
                    path: '/weekly/manage/result/image/:id?',
                    name: 'ResultImageManage',
                    components: {
                        main: require('../components/weekly/manage/ResultImage').default
                    }
                },
                {
                    path: '/weekly/manage/result/pdf/:id?',
                    name: 'ResultPdfManage',
                    components: {
                        main: require('../components/weekly/manage/ResultPdf').default
                    }
                },
                {
                    path: '/weekly/manage/email/template',
                    name: 'EmailTemplate',
                    components: {
                        main: require('../components/weekly/manage/EmailTemplate').default
                    }
                },
                {
                    path: '/weekly/results/total',
                    name: 'TotalResultList',
                    components: {
                        main: require('../components/weekly/results/TotalResultList').default
                    }
                },
                {
                    path: '/weekly/results/items',
                    name: 'ItemResultList',
                    components: {
                        main: require('../components/weekly/results/ItemResultList').default
                    }
                },
                {
                    path: '/weekly/results/trainees',
                    name: 'WeeklyResultList',
                    components: {
                        main: require('../components/weekly/results/ResultList').default
                    }
                },

                {
                    path: '/butterfly',
                    redirect: '/butterfly/results/trainees',
                },

                {
                    path: '/butterfly/results/trainees',
                    name: 'ButterflyResultList',
                    components: {
                        main: require('../components/butterfly/results/ResultList').default
                    }
                },
                {
                    path: '/butterfly/results/ps',
                    name: 'PsResultList',
                    components: {
                        main: require('../components/butterfly/results/PsResultList').default
                    }
                },
                {
                    path: '/butterfly/results/survey',
                    name: 'SurveyResultList',
                    components: {
                        main: require('../components/butterfly/results/SurveyResultList').default
                    }
                },


                {
                    path: '/butterfly/trainees',
                    name: 'ButterflyTraineeList',
                    components: {
                        main: require('../components/butterfly/trainees/TraineeList').default
                    }
                },
                {
                    path: '/butterfly/trainee/form/:id?',
                    name: 'ButterflyTraineeForm',
                    components: {
                        main: require('../components/butterfly/trainees/TraineeForm').default
                    }
                },

                {
                    path: '/butterfly/manage/survey',
                    name: 'Survey',
                    components: {
                        main: require('../components/butterfly/manage/Survey').default
                    }
                },

                {
                    path: '/butterfly/manage/email/template',
                    name: 'ButterflyEmailTemplate',
                    components: {
                        main: require('../components/butterfly/manage/EmailTemplate').default
                    }
                },
            ]
        },
    ]
})
