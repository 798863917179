<template>
    <v-layout align-center justify-center fill-height>
        <v-card
                elevation="0"
        >
            <v-card-title class="justify-center kb-color login-title pb-10">
                <img src="https://skplay.s3.ap-northeast-2.amazonaws.com/butterfly/images/logo_skplay_admin.png" height="118" />
            </v-card-title>
            <v-card-text class="pb-2">
                <v-form ref="form"
                        v-model="valid"
                        lazy-validation>
                    <v-layout align-center
                              column
                              class="mt-5"
                    >
                        <v-text-field ref="username"
                                      label="ID"
                                      outlined
                                      rounded
                                      dense
                                      clearable
                                      class="input-field"
                                      color="#FD7746"
                                      v-model="username"
                                      required
                                      :rules="[v => !!v || '아이디를 입력하세요.']"
                        />
                        <v-text-field ref="passwd"
                                      label="Password"
                                      type="password"
                                      outlined
                                      rounded
                                      dense
                                      clearable
                                      class="input-field"
                                      color="#FD7746"
                                      v-model="passwd"
                                      required
                                      :rules="[v => !!v || '비밀번호를 입력하세요.']"
                                      @keyup.enter="login()"
                        />

                        <v-card-actions>
                            <v-btn rounded color="kb-secondary" class="btn-login" @click="login()">SIGN IN</v-btn>
                        </v-card-actions>
                    </v-layout>
                </v-form>
            </v-card-text>
        </v-card>
    </v-layout>
</template>

<script>
    export default {
        name: 'Login',

        components: {
        },

        data() {
            return {
                valid: true,
                username: '',
                passwd: '',
            }
        },

        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.$store.commit('hideAppBar')
            })
        },
        beforeCreate() {
            if (this.$cookie.get('token')) {
                this.axios.get(`${process.env.VUE_APP_WEEKLY_SERVER_HOST}/api/admin/verifyToken`).then(response => {
                    if (response.data.code === '0000') {
                        this.$cookie.set('training_id', 3)
                        this.$router.replace('/weekly/results/total')
                    }
                })
            }
        },

        mounted() {
        },

        methods: {
            login() {
                const self = this

                if (!this.$refs.form.validate()) return

                const params = {
                    username: this.username,
                    passwd: this.passwd,
                }
                this.axios.post(`${process.env.VUE_APP_WEEKLY_SERVER_HOST}/api/admin/login`, params).then(response => {
                    if (response.data.code === '9011') {

                        self.$store.commit('showDialog',{
                            type: 'alert',
                            title: "로그인 실패",
                            message: response.data.message,
                            okCb: ()=>{
                                setTimeout(this.$refs.username.focus)
                            }
                        });
                    } else if (response.data.code === '9012') {

                        self.$store.commit('showDialog',{
                            type: 'alert',
                            title: "로그인 실패",
                            message: response.data.message,
                            okCb: ()=>{
                                setTimeout(self.$refs.passwd.focus)
                            }
                        });
                    } else if (response.data.code === '0000') {
                        self.$cookie.set('token', response.data.result.token)
                        self.$cookie.set('training_id', 3)
                        self.$cookie.set('isu', response.data.result.is_superuser)
                        this.$router.push('/weekly/results/total')
                    }
                })
                    .catch(error => {
                        console.log(error)
                    })
            }

        }
    };
</script>

<style scoped lang="scss">
    .login-title {
        img {
            display: inline-block;
        }

        font-size: 40px;
        font-weight: 600;
    }

    .v-input {
        width: 300px;
    }

    .btn-login {
        width: 300px;
    }
</style>
