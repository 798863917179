<template>
    <v-main class="fill-height pt-16 main-wrap">
        <v-navigation-drawer
                v-model="drawer"
                app
                @open-drawer="drawer = true"
                @close-drawer="drawer = true"
                class="mt-16"
        >
            <v-list dense class="pt-0">
                <div v-if="item.tpc ? !!isu : true" v-for="(item, index) in menuItems[selected]" :key="index">
                    <v-divider v-if="item.type === 'divider'"></v-divider>
                    <v-list-item v-else
                                 :to="{path: `${item.to}`}"
                                 :exact="false">
                        <v-list-item-action>
                            <v-icon>{{item.icon}}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{item.name}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar color="default" flat fixed>
            <v-app-bar-nav-icon @click="drawer = !drawer" color="white"></v-app-bar-nav-icon>
            <v-toolbar-title class="app-title"><img src="https://skplay.s3.ap-northeast-2.amazonaws.com/butterfly/images/logo_skplay_top.png" /></v-toolbar-title>
            <v-spacer></v-spacer>
            <button class="btn btn-primary btn-module"
                    :class="selected === 'm1' ? 'active' : ''"
                    style="width:170px" @click="changeModule" data-module="1">M1. Weekly!</button>
            <button class="btn btn-primary btn-module ml-3"
                    :class="selected === 'm2' ? 'active' : ''"
                    style="width:170px" @click="changeModule" data-module="2">M2. Butterfly</button>
            <v-spacer></v-spacer>
            <!--            <div class="btn-group">-->
            <!--                <button type="button" class="btn btn-primary mr-3 dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
            <!--                    수정/관리-->
            <!--                </button>-->
            <!--                <div class="dropdown-menu">-->
            <!--                    <a class="dropdown-item" @click="moveTo('/manage/items')">문항</a>-->
            <!--                    <a class="dropdown-item" @click="moveTo('/manage/audio')">효과음</a>-->
            <!--                    <a class="dropdown-item" @click="moveTo('/manage/result/image')">결과 이미지</a>-->
            <!--                    <a class="dropdown-item" @click="moveTo('/manage/result/pdf')">결과 PDF</a>-->
            <!--                    <a class="dropdown-item" @click="moveTo('/manage/email/template')">이메일 템플릿</a>-->
            <!--                </div>-->
            <!--            </div>-->

            <button v-if="isu" class="btn btn-secondary mr-2" @click="$router.push('/manage/user')">관리자계정 관리</button>
            <button class="btn btn-secondary" @click="logout()">로그아웃</button>
        </v-app-bar>

        <v-layout fill-height class="pt-6 pb-6 ml-3 mr-3">

            <router-view name="main" class="main"></router-view>

        </v-layout>
    </v-main>
</template>
<script>
    export default {
        data() {
            return {
                drawer: true,
                group: null,
                selected: 'm1',
                menuItems: {
                    m1: [
                        // {to: '/training', icon: 'mdi-format-list-text', name: '교육과정', tpc: true},
                        {to: '/weekly/results/total', icon: 'mdi-chart-bar', name: '학습결과 종합', tpc: false},
                        {to: '/weekly/results/trainees', icon: 'mdi-account-details-outline', name: '학습자별 결과', tpc: false},
                        {to: '/weekly/results/items', icon: 'mdi-format-list-text', name: '문항별 결과', tpc: false},
                        {type: 'divider', tpc:true},
                        {to: '/weekly/trainees', icon: 'mdi-card-account-details-outline', name: '교육생목록', tpc: true},
                        {type: 'divider', tpc:true},
                        {to: '/weekly/manage/items', icon: 'mdi-format-list-numbered', name: '문항', tpc: true},
                        {to: '/weekly/manage/audio', icon: 'mdi-music-box-multiple-outline', name: '효과음', tpc: true},
                        {type: 'divider', tpc:true},
                        {to: '/weekly/manage/result/image', icon: 'mdi-file-image-outline', name: '결과 이미지', tpc: true},
                        {to: '/weekly/manage/result/pdf', icon: 'mdi-file-pdf-outline', name: '결과 PDF', tpc: true},
                        {type: 'divider', tpc:true},
                        {to: '/weekly/manage/email/template', icon: 'mdi-email-edit-outline', name: '이메일 템플릿', tpc: true},
                    ],
                    m2: [
                        {to: '/butterfly/results/trainees', icon: 'mdi-account-details-outline', name: '학습자별 결과', tpc: false},
                        {to: '/butterfly/results/ps', icon: 'mdi-format-list-text', name: 'PS 문항 결과', tpc: false},
                        {to: '/butterfly/results/survey', icon: 'mdi-ballot-outline', name: 'Survey 문항 결과', tpc: false},
                        {type: 'divider', tpc:false},
                        {to: '/butterfly/manage/survey', icon: 'mdi-format-list-numbered', name: 'Survey 문항', tpc: false},
                        {to: '/butterfly/manage/email/template', icon: 'mdi-email-edit-outline', name: '이메일 템플릿', tpc: false},
                        {type: 'divider', tpc:true},
                        {to: '/butterfly/trainees', icon: 'mdi-card-account-details-outline', name: '교육생목록', tpc: true},
                    ]
                }
            }
        },
        computed: {
            isu() {
                return parseInt(this.$cookie.get('isu', ''))
            }
        },
        mounted() {
            this.$eventBus.$on('MOVE_TO', this.moveTo)

            if (this.$route.path.indexOf('butterfly') > -1) {
                this.$set(this, 'selected', 'm2')
            }
        },
        beforeCreate() {
            if (!this.$cookie.get('token')) {
                this.$router.replace('/')
            }
        },
        methods: {
            logout() {
                this.$cookie.delete('token')
                this.$router.push('/')
            },
            moveTo(path) {
                if (this.$route.path !== path) this.$router.push(path)
            },
            changeModule($e) {
                const target = $e.target
                const module = `m${$(target).data('module')}`

                if (this.selected === module) return


                $('.btn-module').removeClass('active')
                $(target).addClass('active')

                this.selected = module

                if (module === 'm1') {
                    this.$router.push('/weekly')
                } else {
                    this.$router.push('/butterfly')
                }
            }
        }
    }
</script>
<style scoped>
    .app-title {
        font-family: GmarketSans, sans-serif;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.54) !important;
    }

    .main-wrap {
        font-family: GmarketSans, sans-serif;
    }

    .main {
        /*max-width: 1280px;*/
        min-width: 1010px;
        /*margin: 0 auto;*/
    }

    .btn-primary.active {
        border: 2px solid #ce4504 !important;
        background-image: linear-gradient(
                109deg
                , #ff6900 10%, #ff4e00 102%);
        font-weight: 600;
        cursor: default !important;
        box-shadow: none !important;
    }
</style>

<style>
    .btn-primary {
        color: #fff !important;
        border: 2px solid #FFD6AE !important;
        background-image: linear-gradient(109deg, #ff9850 10%, #ff6e2e 102%);
    }
    .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
        color: #fff !important;
        background-color: #60584C !important;
        border: 2px solid #60584C !important;

    }
    .btn-secondary {
        color: #ffffff !important;
        border: 2px solid #60584C!important;
        background-color: #60584c !important;
    }
    .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active, .btn-secondary.active, .open>.dropdown-toggle.btn-secondary {
        color: #fff !important;
        background-color: #464038 !important;
        border: 2px solid #464038 !important;

    }
    .btn:focus,.btn:active {
        outline: none !important;
        box-shadow: 0px 0px 1px 2px #a59a8f !important;
    }
    .btn {
        padding-top: 3px !important;
        padding-bottom: 3px !important;
    }
    .btn:focus i, .btn:active i, .btn:hover i {
        color: #ffffff !important;
    }

    .dropdown-menu {
        margin-top: 0px !important;
        border: 2px solid #464038 !important;
    }
    .dropdown-menu > a:hover {
        background-image: none;
        background-color: #464038;
        color: #fff;
    }

    .left-menu-item {
        font-size: 15px !important;
        line-height: 2.2 !important;
    }
    a:-webkit-any-link {
        text-decoration: none !important;
    }
    hr.v-divider {
        margin-top: 2px;
        margin-bottom: 2px;
    }
</style>
