import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookie from 'vue-cookie'
import App from './App.vue'
import router from './router'

import vuetify from './plugins/vuetify'
import VueMoment from 'vue-moment'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

import eventBus from './plugins/EventBus'
import store from './plugins/Store'

import 'vue-loaders/dist/vue-loaders.css';
import VueLoaders from "vue-loaders"

import './assets/styles/main.scss'

import VueLodash from "vue-lodash"
import lodash from 'lodash'

Vue.use(VueLoaders)
Vue.use(VueLodash, { name: 'custom', lodash: lodash})

Vue.filter('leftPad',  function (value) {
	return ('000'+value).slice(-3);
});

global.jQuery = require('jquery');
const $ = global.jQuery;
window.jQuery = window.$ = $;
require('scrolltofixed/jquery-scrolltofixed-min.js')

window.moment = require('moment')

Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.use(VueCookie)
Vue.use(VueMoment)
Vue.use(eventBus)

new Vue({
	vuetify,
	eventBus,
	router,
	store,
	render: h => h(App)
}).$mount('#app')
