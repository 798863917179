<template>
    <v-card width="100%">
        <v-card-actions>
            <v-layout class="w-25 companies">
                <v-select
                        label="소속회사"
                        :items="Object.keys(companies)"
                        hide-details
                        @change="onSelectCompany"
                />
                <v-select
                        class="ml-5"
                        label="부서"
                        :items="divisions"
                        v-model="division"
                        hide-details
                        @change="getList"
                />
            </v-layout>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-text>
            <v-simple-table class="table">
                <thead>
                <tr>
                    <th rowspan="2">No.</th>
                    <th rowspan="2">Survey<br />Number</th>
                    <th rowspan="2">문항</th>
                    <th colspan="4" class="text-center">1회차</th>
                </tr>
                <tr>
                    <th>선택지#1</th>
                    <th>선택지#2</th>
                    <th>선택지#3</th>
                    <th>선택지#4</th>
                </tr>
                </thead>
                <tbody>
                <tr
                        v-for="([id, item], index) in Object.entries(list)"
                >
                    <td> {{ index + 1 }}</td>
                    <td> {{ item.survey_id }}</td>
                    <td> {{ item.survey.episode }}ROUND<br />{{ item.survey.no }}번 문항</td>
                    <td v-for="n in 4"
                    >
                        {{ item[`option${n}`] || '-'}}
                        <span v-if="item[`option${n}`] && item.total">
                            ({{ Math.round(item[`option${n}`] / item.total * 100) }}%)
                        </span>
                    </td>
                </tr>
                <tr
                        v-if="!Object.keys(list).length"
                >
                    <td colspan="9">결과가 없습니다.</td>
                </tr>
                </tbody>
            </v-simple-table>
            <v-divider></v-divider>
        </v-card-text>

        <v-card-actions>
            <v-btn color="btn-secondary" @click="downloadExcel()">
                엑셀 다운로드
            </v-btn>
        </v-card-actions>

    </v-card>
</template>

<script>

    export default {
        data() {
            return {
                companies: {},
                company: '전체',
                divisions: [],
                division: '전체',
                list: {},
                isLoading: true,
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            onSelectCompany(company) {

                this.company = company
                this.divisions = ['전체']
                this.division = '전체'
                Object.keys(this.companies[company].division).forEach(division => {
                    this.divisions.push(division)
                })

                this.getList()
            },
            getList() {
                const params = {
                    company: (this.company === '전체' ? '' : this.company),
                    division: this.division === '전체' ? '' : this.division,
                }
                this.axios.get(`${process.env.VUE_APP_BUTTERFLY_SERVER_HOST}/api/admin/results/survey`, {params: params} ).then(response => {
                    if (response.data.code === '0000') {

                        const result = response.data.result

                        this.companies = {
                            '전체': {division: {}}
                        }
                        result.companies.forEach(item => {
                            if (!item.company) return
                            if (!this.companies[item.company]) {
                                this.companies[item.company] = { division: {} }
                            }

                            if (!item.division) return
                            if (!this.companies[item.company].division[item.division]) {
                                this.companies[item.company].division[item.division] = ''
                            }

                        })

                        const list = {}
                        result.list.forEach(item => {
                            if (!list[item.survey_id]) {
                                list[item.survey_id] = {}
                            }

                            list[item.survey_id].survey_id = item.survey_id
                            list[item.survey_id].survey = item.survey
                            list[item.survey_id][`option${item.selected}`] = item.cnt
                        })

                        Object.keys(list).forEach(no => {
                            const option = list[no]
                            _.range(1, 5).forEach(n => {
                                if (!option.total) {
                                    option.total = 0
                                }

                                option.total += option[`option${n}`] || 0
                            })
                        })

                        console.log(list)

                        this.list = list

                    }
                })

            },
            downloadExcel() {
                const params = {
                    company: this.company !== '전체' ? this.company : '',
                    division: this.division !== '전체' ? this.division : '',
                }
                this.axios({
                    url: `${process.env.VUE_APP_BUTTERFLY_SERVER_HOST}/api/admin/results/survey/excel`,
                    method: 'GET',
                    params: params,
                    responseType: 'blob',
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    let fileName = 'Survey문항_결과'
                    if (this.company !== '전체') fileName += '_' + this.company
                    if (this.division !== '전체') fileName += '_' + this.division

                    link.href = url;
                    link.setAttribute('download', `${fileName}_${moment().format('YYYY-MM-DD')}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                });
            },
        }
    }
</script>

<style lang="scss" scoped>

    .table {
        width: 1024px;
    }

    .theme--light.v-data-table > .v-data-table__wrapper > table > thead   th:nth-child(5)  {
        border-left: 1px solid #ccc;
    }
    .theme--light.v-data-table > .v-data-table__wrapper > table > thead   th:first-child  {
        border-bottom: thin solid #ccc;
    }
    tr:first-child  > th {
        border-top: thin solid #ccc;
        border-bottom: thin solid #ccc;
    }
    tr:last-child {
        border-bottom: thin solid #ccc;
    }
    th:first-child, td:first-child {
        border-left: thin solid #ccc;
    }
    th, td {
        border-right: thin solid #ccc;
        padding: 0.75em !important;
        text-align: center !important;
    }
    th {
        background-color: #efefef;
    }

    .companies, .search {
        font-family: Roboto, sans-serif;
    }
</style>
