<template>
    <div>
        <v-card width="1121px" style="overflow: auto">
            <v-card-text class="pb-16">
                <v-simple-table dense >
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-center">
                                문항
                            </th>
                            <th class="text-center">
                                화면
                            </th>
                            <th class="text-center">
                                세부척도
                            </th>
                            <th class="text-center">
                                인원(명)
                            </th>
                            <th class="text-center">
                                비율(%)
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                                v-for="(item, index) in results"
                                :key="index"
                        >
                            <td class="text-center" v-if="index % 2 === 0" :rowspan="index % 2 === 0 ? 2 : 1">{{item.result_sequence.toString().padStart(2, '0')}}
                            </td>
                            <td class="text-center" v-if="index % 2 === 0" :rowspan="index % 2 === 0 ? 2 : 1">
                                <img :src="item.preview" height="300" />
                            </td>
                            <td class="text-center">{{ item.type }}</td>
                            <td class="text-center">{{ item.aggregate }}</td>
                            <td class="text-center">{{ item.rate}}%</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
            <v-card-actions>
                <v-btn color="btn-secondary" @click="downloadResultExcel()">
                    엑셀 다운로드
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>

        </v-card>
    </div>
</template>

<script>

    export default {
        components: {
        },
        data() {
            return {
                isLoading: true,
                results: [],
                showDialog: false,
                total: 0,
                completed: 0,
                incompleted: 0,
            }
        },
        watch: {
            showDialog() {
                this.file = null
            }
        },
        mounted() {


            this.getList()
        },
        methods: {
            getList() {
                this.isLoading = true
                const params = {
                    id: this.$cookie.get('training_id')
                }
                this.axios.get(`${process.env.VUE_APP_WEEKLY_SERVER_HOST}/api/admin/result/itemResult`, {params: params}).then(response => {
                    if (response.data.code === '0000') {

                        let lastItem = null
                        const results = []
                        response.data.result.forEach((item, index) => {
                            if (index % 2 === 1) {
                                const total = lastItem.aggregate + item.aggregate
                                lastItem.rate = Math.round(lastItem.aggregate / total * 1000) / 10 || 0
                                item.rate = Math.round(item.aggregate / total * 1000) / 10 || 0

                                results.push(lastItem)
                                results.push(item)
                            } else {
                                lastItem = item
                            }
                        })

                        this.results = results
                    }
                })
            },

            moveTo(path) {
                this.$eventBus.$emit('MOVE_TO', path)
            },

            downloadResultExcel() {
                this.axios({
                    url: `${process.env.VUE_APP_WEEKLY_SERVER_HOST}/api/admin/result/item/excel`,
                    method: 'GET',
                    responseType: 'blob',
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `문항별_결과${moment().format('YYYY-MM-DD')}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .list_title { height: 64px;}


    .legend { margin-top: -30px; width: 98%; background-color: #ffffff; height: 30px;}

    td + td {
        border-left: 1px solid rgba(0,0,0,0.12);
    }
    tr {
        border-bottom: 1px solid rgba(0,0,0,0.12);
    }
</style>
