<template>
    <v-card width="100%">
        <v-card-text>
            <v-layout id="chartHolder" class="charts" justify-space-between>

                <div class="chart-wrap w-100">
                    <strong>[1회차]</strong>
                    <div id="chart1" class="chart"></div>
                </div>
                <div class="chart-wrap w-100">
                    <strong>[2회차]</strong>
                    <div id="chart2" class="chart"></div>
                </div>
                <v-layout class="legend font-weight-bold" align-center justify-center>
                    [전체 과정 결과]
                    <img src="/images/icon_chart_total.png" class="ml-5 mr-2" /> 전체 인원 : {{ total }}
                    <img src="/images/icon_chart_completed.png" class="ml-5 mr-2" /> 완료 인원 : {{ completed }}
                    <img src="/images/icon_chart_incompleted.png" class="ml-5 mr-2" /> 미완료 인원 : {{ incompleted }}
                </v-layout>
            </v-layout>
        </v-card-text>
        <v-card-actions class="btn-download">
            <v-btn color="btn-secondary" @click="downloadResultChart()">
                이미지 다운로드
            </v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-actions>
            <v-layout class="w-25 companies">
                <v-select
                        label="소속회사"
                        :items="Object.keys(companies)"
                        hide-details
                        @change="onSelectCompany"
                />
                <v-select
                        class="ml-5"
                        label="부서"
                        :items="divisions"
                        v-model="division"
                        hide-details
                        @change="getList"
                />
            </v-layout>
            <v-spacer></v-spacer>
            <div class="w-25">
                <v-text-field
                        class="search"
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="이름, e-mail, 소속회사, 부서"
                        single-line
                        hide-details
                        @keypress.enter="getList"
                ></v-text-field>
            </div>
        </v-card-actions>
        <v-card-text>
            <v-data-table
                    v-model="selected"
                    item-key="id"
                    :headers="headers"
                    :items="trainees"
                    :items-per-page="itemsPerPage"
                    :loading="isLoading"
                    :expanded.sync="expanded"
                    single-expand
                    show-expand
                    loading-text="데이터 조회 중.. 잠시만 기다리세요."
                    hide-default-footer
            >
                <template v-slot:item.name="{ item, index }">
                    <a class="name" @click="detail(item)">{{ item.name }}</a>
                </template>
                <template v-slot:item.score1="{ item, index }">
                    <div style="width: 80px;">
                        {{ item.score1}}<br />
                        <span v-for="n in item.star1"
                              v-if="item.star1"><img src="https://skplay.s3.ap-northeast-2.amazonaws.com/butterfly/images/star_psi_on.png" style="width: 15px" /></span>
                    </div>
                </template>
                <template v-slot:item.score2="{ item, index }">
                    <div style="width: 80px;">
                        {{ item.score2}}<br />
                        <span v-for="n in item.star2"
                              v-if="item.star2"><img src="https://skplay.s3.ap-northeast-2.amazonaws.com/butterfly/images/star_psi_on.png" style="width: 15px" /></span>
                    </div>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" style="padding: 16px">
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th></th>
                                    <th>#1</th>
                                    <th>#2</th>
                                    <th>#3</th>
                                    <th>#4</th>
                                    <th>#5</th>
                                    <th>#6</th>
                                    <th>#7</th>
                                    <th>#8</th>
                                    <th>#9</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-if="item.round1">
                                    <td><span>1회차</span></td>
                                    <td v-for="n in Object.keys(item.round1.psiScores)"
                                        v-text="item.round1.psiScores[n]">
                                    </td>
                                </tr>
                                <tr v-if="item.round2">
                                    <td><span>2회차</span></td>
                                    <td v-for="n in Object.keys(item.round2.psiScores)"
                                        v-text="item.round2.psiScores[n]">
                                    </td>
                                </tr>
                                <tr v-if="!item.round1 && !item.round2">
                                    <td :colspan="headers.length" class="text-center">
                                        결과가 없습니다.
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </td>
                </template>
                <template v-slot:item.action="{ item, index }">
                    <v-btn color="#FD7746" small class="btn-reset"
                           @click="resetItem(item, index)"
                    >초기화</v-btn>
                </template>
            </v-data-table>
            <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
            <v-btn color="btn-secondary" @click="downloadResultExcel()">
                엑셀 다운로드
            </v-btn>
            <v-spacer></v-spacer>
            <div class="text-center pt-2 mt-5">
                <v-pagination
                        v-model="page"
                        :length="pageCount"
                ></v-pagination>
            </div>
            <v-spacer></v-spacer>
            <div class="pl-5 position-absolute" style="right: 20px; width:100px; margin-top: 30px;">
                <v-select label="표시갯수"
                          v-model="itemsPerPage"
                          :items="[10,15,20,30,50,100,200,1000]"
                          @change="getList"
                />
            </div>
        </v-card-actions>


        <v-dialog v-model="showDetail" width="1024" style="overflow-x:hidden">
            <v-card>
                <v-card-title>
                    {{item.name}}님 결과 상세
                </v-card-title>
                <v-card-text>
                    <v-tabs
                            v-model="tab"
                            grow
                            color="deep-orange accent-4"
                    >
                        <v-tab
                                v-for="item in ['Psychological Safety 문항', 'Survey 문항']"
                                :key="item"
                        >
                            {{ item }}

                        </v-tab>

                    </v-tabs>
                    <v-tabs-items v-model="tab"
                                  class="lists"
                    >
                        <v-tab-item >
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th rowspan="2">No.</th>
                                        <th colspan="4">1회차</th>
                                        <th colspan="4">2회차</th>
                                    </tr>
                                    <tr>
                                        <th>선택지#1</th>
                                        <th>선택지#2</th>
                                        <th>선택지#3</th>
                                        <th>선택지#4</th>
                                        <th>선택지#1</th>
                                        <th>선택지#2</th>
                                        <th>선택지#3</th>
                                        <th>선택지#4</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr
                                            v-for="(row, index) in item.options"
                                    >
                                        <td>{{ index + 1}}</td>
                                        <td v-for="n in 4"
                                            :class="n === row[1] ? 'selected' : ''"
                                            v-text="n === row[1] ? n : '-'"
                                        ></td>
                                        <td v-for="n in 4"
                                            :class="n === row[2] ? 'selected' : ''"
                                            v-text="n === row[2] ? n : '-'"
                                        ></td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-tab-item>
                        <v-tab-item>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th rowspan="2">No.</th>
                                        <th rowspan="2">Survey<br />Number</th>
                                        <th rowspan="2">문항</th>
                                        <th colspan="4">1회차</th>
                                    </tr>
                                    <tr>
                                        <th>선택지#1</th>
                                        <th>선택지#2</th>
                                        <th>선택지#3</th>
                                        <th>선택지#4</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr
                                            v-for="(row, index) in item.surveys"
                                    >
                                        <td>{{ index + 1}}</td>
                                        <td>{{ row.survey_id }}</td>
                                        <td>{{ row.no }}</td>
                                        <td v-for="n in 4"
                                            :class="n === row.selected ? 'selected' : ''"
                                            v-text="n === row.selected ? n : '-'"
                                        ></td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="secondary" @click.native="downloadDetailExcel">엑셀 다운로드</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click.native="showDetail = false">확인</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-card>
</template>

<script>
    import domtoimage from 'dom-to-image'

    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
    import am4themes_animated from "@amcharts/amcharts4/themes/animated";

    am4core.useTheme(am4themes_animated);

    export default {
        components: {
            domtoimage,
        },
        data() {
            return {
                moment: moment,
                page: 1,
                pageCount: 2,
                itemsPerPage: 100,
                companies: {},
                company: '전체',
                divisions: [],
                division: '전체',
                trainees: [],
                expanded: [],
                headers: [
                    {text: '이름', sortable: true, value: 'name', },
                    {text: '이메일', sortable: true, value: 'email', },
                    {text: '소속회사', sortable: true, value: 'company', },
                    {text: '부서', sortable: true, value: 'division', },
                    {text: '진행상태', sortable: true, value: 'status', },
                    // {text: '시작 시간', sortable: true, value: 'created_at', },
                    // {text: '완료 시간', sortable: true, value: 'completed_at', },
                    // {text: '걸린 시간', sortable: true, value: 'duration', },
                    {text: '성공 여부', sortable: true, value: 'success', },
                    {text: '1회차 점수', sortable: true, value: 'score1', },
                    {text: '2회차 점수', sortable: true, value: 'score2', },
                    {text: '', sortable: true, value: 'action', width: 100 },
                ],
                isLoading: true,
                selected: [],
                search: '',
                // chart: null,

                total: 0,
                completed: 0,
                incompleted: 0,

                showDetail: false,
                item: {},
                tab: '',
            }
        },
        watch: {
            showDialog() {
                this.file = null
            },
            page(val) {
                if (val) {
                    this.getList(null, true)
                }
            }
        },
        mounted() {

            // this.chart.legend = new am4charts.Legend()


            this.isLoading = false
            this.getList()
        },
        methods: {
            onSelectCompany(company) {

                this.company = company
                this.divisions = ['전체']
                this.division = '전체'
                Object.keys(this.companies[company].division).forEach(division => {
                    this.divisions.push(division)
                })

                this.getList()
            },
            getList(e, pageChanged) {
                if (!pageChanged) {
                    this.page = 1
                }

                this.isLoading = true
                const params = {
                    itemsPerPage: this.itemsPerPage,
                    page: this.page,
                    company: this.company === '전체' ? '' : this.company,
                    division: this.division === '전체' ? '' : this.division,
                    keyword: this.search,
                }


                this.axios.get(`${process.env.VUE_APP_BUTTERFLY_SERVER_HOST}/api/admin/results/trainee` , {params: params}).then(response => {

                    if (response.data.code === '0000') {
                        const result = response.data.result

                        this.total = result.totalCount
                        this.completed = result.completed
                        this.incompleted = result.incompleted

                        this.pageCount = Math.ceil(result.totalCount / this.itemsPerPage)

                        // 차트
                        const chartData = {
                            1: {
                                2: {level: 2, count: 0},
                                3: {level: 3, count: 0},
                                4: {level: 4, count: 0},
                                5: {level: 5, count: 0},
                            },
                            2: {
                                2: {level: 2, count: 0},
                                3: {level: 3, count: 0},
                                4: {level: 4, count: 0},
                                5: {level: 5, count: 0},
                            },
                        }
                        result.summary.forEach((item, index) => {
                            chartData[item.round][item.star].count += item.cnt
                        })

                        Object.keys(chartData).forEach(round => {
                            const data = []
                            Object.keys(chartData[round]).forEach(star => {
                                data.push(chartData[round][star])
                            })

                            this.drawChart(round, data)
                        })

                        const list = []
                        result.list.forEach(item => {
                            item.results.forEach(result => {
                                item[`round${result.round}`] = result
                            })

                            list.push(item)
                        })

                        list.forEach(item => {
                            //진행상태
                            item.status = ''
                            item.success = ''


                            if (!item.round1) {
                                item.status = '미완료'
                                item.success = ''
                            } else {
                                if (item.round1.ending === 1 || (item.round2 && item.round2.ending === 1)) {
                                    item.status = '완료'
                                    item.success = '성공'
                                } else if (item.round1.ending === 2 && !item.round2) {
                                    item.status = '미완료'
                                    item.success = ''
                                } else {
                                    item.status = '완료'
                                    item.success = '실패'
                                }
                            }

                            // 시작시간
                            item.created_at = this.dateFormat(item.created_at)

                            // 완료시간
                            if (item.round2) item.completed_at = this.dateFormat(item.round2.created_at)
                            else if (item.round1) item.completed_at = this.dateFormat(item.round1.created_at)

                            // 걸린시간
                            if (item.completed_at) {
                                const diff = moment(item.completed_at).diff(moment(item.created_at))
                                const duration = moment.duration(diff).asMinutes()
                                if (duration > 60) {
                                    item.duration = Math.floor(duration / 60) + '시간 '
                                    item.duration += Math.round(duration - (Math.floor(duration / 60) * 60)) + '분'
                                } else {
                                    item.duration = Math.round(duration) + '분'
                                }
                            }

                            // 점수
                            if (item.round1) {
                                item.score1 = item.round1.score
                                item.star1 = item.round1.star
                                item.round1.psiScores = JSON.parse(item.round1.psiScores)
                            }
                            if (item.round2) {
                                item.score2 = item.round2.score
                                item.star2 = item.round2.star
                                item.round2.psiScores = JSON.parse(item.round2.psiScores)
                            }
                        })

                        this.companies = {
                            '전체': {division: {}}
                        }
                        result.companies.forEach(item => {
                            if (!item.company) return
                            if (!this.companies[item.company]) {
                                this.companies[item.company] = { division: {} }
                            }

                            if (!item.division) return
                            if (!this.companies[item.company].division[item.division]) {
                                this.companies[item.company].division[item.division] = ''
                            }

                        })


                        this.trainees = list

                        this.isLoading = false

                    }

                })

            },
            dateFormat(datetime) {
                return moment(datetime).format('YYYY-MM-DD HH:mm:ss')
            },
            drawChart(id, chartData) {

                let min = -1, max = 0, total = 0, cnt = 0
                chartData.forEach(data => {
                    if (min === -1 || (min > 0 && min > data.count)) {
                        min = data.count
                    }
                    if (max < data.count) {
                        max = data.count
                    }

                    total += data.count
                    cnt++
                })
                let avg = total / cnt

                const chart = am4core.create(`chart${id}`, am4charts.XYChart);
                chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

                chart.padding(10, 40, 40, 40);

                chart.numberFormatter.numberFormat = "#.#"

                let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                categoryAxis.renderer.grid.template.location = 0;
                categoryAxis.dataFields.category = "level";
                categoryAxis.renderer.minGridDistance = 60;
                categoryAxis.fontSize = 11;
                // categoryAxis.renderer.labels.template.rotation = -30;
                categoryAxis.renderer.labels.template.horizontalCenter = "right";
                categoryAxis.title.text = "단계(star)";

                let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.min = 0;
                valueAxis.max = max;
                valueAxis.strictMinMax = true;
                valueAxis.renderer.minGridDistance = 30;
                valueAxis.fontSize = 11;
                valueAxis.title.text = "명";
                valueAxis.calculateTotals = true;


                // axis break
                if (max > avg * 3 && max - avg > 10) {

                    let axisBreak = valueAxis.axisBreaks.create();
                    axisBreak.startValue = Math.round(max - (max - avg) * 9 / 10) ;
                    axisBreak.endValue = Math.round(max - (max - avg) * 2 / 10)
                    //axisBreak.breakSize = 0.005;

                    // fixed axis break
                    let d = (axisBreak.endValue - axisBreak.startValue) / (valueAxis.max - valueAxis.min);
                    axisBreak.breakSize = 0.05 * (1 - d) / d; // 0.05 means that the break will take 5% of the total value axis height

                    // make break expand on hover
                    let hoverState = axisBreak.states.create("hover");
                    hoverState.properties.breakSize = 1;
                    hoverState.properties.opacity = 0.1;
                    hoverState.transitionDuration = 1500;

                    axisBreak.defaultState.transitionDuration = 1000;
                }

                let series = chart.series.push(new am4charts.ColumnSeries());
                series.dataFields.categoryX = "level";
                series.dataFields.valueY = "count";
                series.columns.template.tooltipText = "{valueY.value}명\n{valueY.percent}%";
                series.columns.template.tooltipY = 0;
                series.columns.template.strokeOpacity = 0;
                series.calculatePercent = true
                series.columns.template.width = 30

                let labelBullet = series.bullets.push(new am4charts.LabelBullet());
                labelBullet.label.verticalCenter = "bottom";
                labelBullet.label.dy = 15;
                labelBullet.label.fontSize = 10;
                labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#.')}";

                // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
                series.columns.template.adapter.add("fill", function(fill, target) {
                    return chart.colors.getIndex(target.dataItem.index);
                });

                chart.data = chartData

            },

            moveTo(path) {
                this.$eventBus.$emit('MOVE_TO', path)
            },

            downloadResultChart() {
                const self = this
                this.$store.commit('showLoader')
                domtoimage.toJpeg(document.getElementById('chartHolder'), { quality: 0.95 })
                    .then(function (dataUrl) {
                        const link = document.createElement('a');
                        link.download = `Butterfly_학습자별_결과_${moment().format('YYYY-MM-DD')}.jpeg`;
                        link.href = dataUrl;
                        link.click();
                        self.$store.commit('hideLoader')
                    });

            },
            downloadResultExcel() {
                const params = {
                    company: this.company === '전체' ? '' : this.company,
                    division: this.division === '전체' ? '' : this.division,
                    keyword: this.search,
                }
                this.axios({
                    url: `${process.env.VUE_APP_BUTTERFLY_SERVER_HOST}/api/admin/results/trainee/excel`,
                    method: 'GET',
                    params: params,
                    responseType: 'blob',
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    let fileName = '학습자별_결과'
                    if (this.company !== '전체') fileName += '_' + this.company
                    if (this.division !== '전체') fileName += '_' + this.division
                    link.href = url;
                    link.setAttribute('download', `${fileName}_${moment().format('YYYY-MM-DD')}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                });
            },
            downloadDetailExcel() {
                this.axios({
                    url: `${process.env.VUE_APP_BUTTERFLY_SERVER_HOST}/api/admin/results/trainee/excel/${this.item.id}`,
                    method: 'GET',
                    responseType: 'blob',
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${this.item.name}님_결과_상세_${moment().format('YYYY-MM-DD')}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                });
            },

            resetItem(item, index) {
                const self = this

                this.$store.commit('showDialog', {
                    type: 'confirm',
                    message: `[${item.name}]님의 데이터를 초기화하시겠습니까?`,
                    okCb: _ =>{

                        const params = {
                            id: item.id
                        }

                        self.axios.post(`${process.env.VUE_APP_BUTTERFLY_SERVER_HOST}/api/admin/trainee/reset`, params).then(response => {
                            if (response.data.code === '0000') {
                                self.getList()
                            }
                        })
                    }
                })
            },

            detail(item) {

                this.item = {}

                this.axios.get(`${process.env.VUE_APP_BUTTERFLY_SERVER_HOST}/api/admin/results/trainee/${item.id}`).then(response => {


                    this.item = _.cloneDeep(item)
                    this.tab = 0

                    const options = response.data.result.options
                    const surveys = response.data.result.surveys

                    let items = {}
                    let list = []
                    options.forEach(item => {
                        if (!items[item.sequence_id]) {
                            items[item.sequence_id] = {}
                        }
                        if (!items[item.sequence_id][item.round]) {
                            items[item.sequence_id][item.round] = item.option.no
                        }
                    })
                    Object.keys(items).forEach(key => {
                        list.push(items[key])
                    })

                    this.item.options = list
                    this.item.surveys = surveys
                    this.showDetail = true

                    setTimeout(_ => {
                        $('.lists').scrollTop(0)
                    })

                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .charts {
        padding-top: 10px;
        padding-bottom: 50px;
        position: relative;

        .chart-wrap {
            height: 380px;
            overflow: hidden;

            strong {
                margin-left: 100px;
            }
            .chart { width: 100%; height: 400px; }
        }
    }

    .btn-download {
        margin-top: -55px;
    }
    .list_title { height: 64px;}

    #chartHolder {
        background-color: #ffffff;
    }

    .legend {
        position: absolute;
        bottom: 0;
        margin-top: -30px;
        width: 98%;
        background-color: #ffffff;
        height: 30px;
    }

    .companies, .search {
        font-family: Roboto, sans-serif;
    }

    .btn-reset { color: #fff !important;}

    .name {
        cursor: pointer;
        text-decoration: underline;

        &:hover {
            color: var(--orange);
        }
    }


    .theme--light.v-data-table > .v-data-table__wrapper > table > thead   th:nth-child(5)  {
        border-left: 1px solid #ccc;
    }
    .theme--light.v-data-table > .v-data-table__wrapper > table > thead   th:first-child  {
        border-bottom: thin solid #ccc;
    }
    tr:first-child  > th {
        border-top: thin solid #ccc;
        border-bottom: thin solid #ccc;
    }
    tr:last-child {
        border-bottom: thin solid #ccc;
    }
    th:first-child, td:first-child {
        border-left: thin solid #ccc;
    }
    th, td {
        border-right: thin solid #ccc;
        padding: 0.75em !important;
        text-align: center !important;
    }
    th {
        background-color: #efefef;
    }
    td.selected {
        background: var(--yellow);
        font-weight: 600;
        color: #b15a11;
        font-size: 14px !important;
    }

    .lists {
        height: 70vh;
        overflow-y: scroll;
        background-color: #f6f6f6 !important;
        border: 1px solid #ccc;
    }
</style>
